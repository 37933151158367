import DOMPurify from 'dompurify';

if (window.trustedTypes && !window.trustedTypes.defaultPolicy) {
  window.trustedTypes.createPolicy('default', {
    createHTML: (string) =>
      DOMPurify.sanitize(string, { RETURN_TRUSTED_TYPE: true }),
    createScriptURL: (url) => url, // Allow trusted script URLs
    createScript: (script) => script, // Allows script content if necessary
  });
}
