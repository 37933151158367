import LoadingScreen from 'components/LoadingScreen';
import useUserAccountState from 'hooks/useUserAccountState';
import { ReactNode } from 'react';
import { StatsigProvider } from 'statsig-react';

type StatsigProviderProps = {
  children: ReactNode;
  sdkKey: string;
};

export function EnhancedStatsigProvider({
  children,
  sdkKey,
}: StatsigProviderProps) {
  const {
    user,
    account,
    currentOrganization: organization,
  } = useUserAccountState();

  let userEmail = user.email;
  // Check if the app is running on localhost
  const isLocalhost = window.location.hostname === 'localhost';

  const options = isLocalhost
    ? {} // No options on localhost
    : {
        api: `${window.location.origin}/featuregates`,
        eventLoggingApi: `${window.location.origin}/featuregates/events`,
      };
  if (!sdkKey) {
    return <>{children}</>;
  }
  return (
    <StatsigProvider
      sdkKey={sdkKey}
      user={{
        userID: userEmail,
        email: userEmail,
        custom: {
          name: user.name,
          website: location.host,
          accountId: account?.accountId,
          orgId: organization?.id,
        },
      }}
      options={options}
      waitForInitialization={true}
      initializingComponent={<LoadingScreen />}
    >
      {children}
    </StatsigProvider>
  );
}
